import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AppBar, Toolbar, Typography, Container, Paper, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, InputLabel, Select, MenuItem, IconButton, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddEventForm from '../components/AddEventForm';
import EventIcon from '@mui/icons-material/Event';
import CategoryIcon from '@mui/icons-material/Category';
import LabelIcon from '@mui/icons-material/Label';
import BusinessIcon from '@mui/icons-material/Business';
import PlaceIcon from '@mui/icons-material/Place';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; 
import { jwtDecode } from 'jwt-decode'; // เปลี่ยนการ import ให้ถูกต้อง

const localizer = momentLocalizer(moment);

const AdminCalendar = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  //const [errorMessage, setErrorMessage] = useState('');
  const [setErrorMessage] = useState('');
  
  useEffect(() => {
    const userRole = localStorage.getItem('role');
    setRole(userRole);
  }, []);

  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    event_name: '',
    event_type: 'กิจกรรม',
    event_categories: 'ทั่วไป',
    event_department: '',
    event_place: '',
    event_start_datetime: '',
    event_end_datetime: '',
    event_remark: '',
    event_participants_amount: '',
    event_document: '',
    event_link: '',
    event_detail: '', // เพิ่มฟิลด์นี้
  });

  const [filter, setFilter] = useState({
    search: '',
    type: '',
    category: '',
    department: '',
    startDate: '',
    endDate: '',
  });

  const [openForm, setOpenForm] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  // Add handleSelectSlot function to define it
  const handleSelectSlot = ({ start, end }) => {
    setNewEvent({
      event_name: '',
      event_type: 'กิจกรรม',
      event_categories: 'ทั่วไป',
      event_department: '',
      event_place: '',
      event_start_datetime: start.toISOString(),  // Set selected start time
      event_end_datetime: end.toISOString(),  // Set selected end time
    });
    setIsEditing(false);  // Set to false as this is adding a new event
    setOpenForm(true);  // Open the form
  };


useEffect(() => {
  const token = localStorage.getItem('token');
  
  if (!token) {
    navigate('/admin-login');
  } else {
    try {
      // Decode token เพื่อตรวจสอบ expiration time
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // เวลาในหน่วยวินาที

      if (decodedToken.exp < currentTime) {
        // Token หมดอายุแล้ว
        localStorage.clear(); // ล้าง localStorage
        navigate('/admin-login'); // ส่งผู้ใช้กลับไปหน้า login
      } else {
        // ถ้า token ยังไม่หมดอายุ ให้ fetch ข้อมูล
        fetch('https://e-calendar.rmutp.ac.th/api/event_listmgt', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            const formattedEvents = data.map(event => ({
              id: event.event_id,
              title: event.event_name,
              start: new Date(event.event_start_datetime),
              end: new Date(event.event_end_datetime),
              event_name: event.event_name,
              event_type: event.event_type,
              event_categories: event.event_categories,
              event_department: event.event_department,
              event_place: event.event_place,
              event_detail: event.event_detail,
              event_remark: event.event_remark,
              event_link: event.event_link
            }));
            setEvents(formattedEvents);
          })
          .catch(error => {
            console.error('Error fetching events:', error);
          });
      }
    } catch (error) {
      console.error('Invalid token', error);
      localStorage.clear(); // ล้าง localStorage
      navigate('/admin-login'); // ส่งผู้ใช้กลับไปหน้า login
    }
  }
}, [navigate]);

  // Logout function
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleResetFilter = () => {
    setFilter({
      search: '',
      type: '',
      category: '',
      department: '',
      startDate: '',
      endDate: '',
    });
  };

  // Function to open the form for adding a new event
  const handleClickOpen = () => {
    setNewEvent({
      event_name: '',
      event_type: 'กิจกรรม',
      event_categories: 'ทั่วไป',
      event_department: '',
      event_place: '',
      event_start_datetime: '',
      event_end_datetime: '',
    });
    setIsEditing(false);  // Set to false when adding a new event
    setOpenForm(true);
  };

  // Function to open the form for editing an event
  const handleEditEvent = () => {
    setNewEvent({
      ...selectedEvent,
      event_start_datetime: new Date(selectedEvent.start).toISOString(),
      event_end_datetime: new Date(selectedEvent.end).toISOString(),
    });
    setIsEditing(true);  // Set to true when editing
    setOpenForm(true);
    setOpenDetailDialog(false);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setIsEditing(false);
  };

  const handleAddEvent = async (event) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('คุณไม่ได้รับสิทธิ์ในการเพิ่มกิจกรรม');
        return;
      }
  
      const formData = {
        event_name: event.event_name,
        event_type: event.event_type,
        event_categories: event.event_categories,
        event_department: event.event_department,
        event_place: event.event_place,
        event_start_datetime: event.event_start_datetime,
        event_end_datetime: event.event_end_datetime,
        event_remark: event.event_remark,
        event_link: event.event_link,
        event_detail: event.event_detail, // เพิ่มฟิลด์นี้ในการส่งข้อมูลไป backend
      };
  
      const response = await fetch('https://e-calendar.rmutp.ac.th/api/add_event', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        //const result = await response.json();
        //const newId = result.event_id;  // Assuming the new ID comes from the response
        //const newEventToAdd = {
        //  ...event,
        //  id: newId,
        //  title: event.event_name,
        //  start: new Date(event.event_start_datetime),
        //  end: new Date(event.event_end_datetime),
        //};
        //setEvents([...events, newEventToAdd]);
        //setOpenForm(false);
        window.location.reload();
      } else {
        console.error('Error adding event:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์');
    }
  };

  const handleSaveEditEvent = async (event) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('คุณไม่ได้รับสิทธิ์ในการแก้ไขกิจกรรม');
        return;
      }
  
      const eventData = {
        event_name: event.event_name,
        event_type: event.event_type,
        event_categories: event.event_categories,
        event_department: event.event_department,
        event_place: event.event_place,
        event_start_datetime: event.event_start_datetime,
        event_end_datetime: event.event_end_datetime,
        event_remark: event.event_remark,
        event_link: event.event_link,
        event_detail: event.event_detail, // เพิ่มฟิลด์นี้ในการแก้ไข
      };
  
      const response = await fetch(`https://e-calendar.rmutp.ac.th/api/update_event/${event.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });
  
      if (response.ok) {
        //const updatedEvents = events.map((e) =>
        //  e.id === event.id
        //    ? { ...event, start: new Date(event.event_start_datetime), end: new Date(event.event_end_datetime) }
        //    : e
        //);
        //setEvents(updatedEvents);
        //setIsEditing(false);
        //setOpenForm(false);
        window.location.reload();
      } else {
        alert('ไม่สามารถทำรายการได้โปรดติดต่อ สวส.');
        console.error('Error updating event:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์');
    }
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };
  
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  // Delete event function
  const handleDeleteEvent = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('คุณไม่ได้รับสิทธิ์ในการลบกิจกรรม');
        return;
      }
  
      const response = await fetch(`https://e-calendar.rmutp.ac.th/api/delete_event/${selectedEvent.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const updatedEvents = events.filter((e) => e.id !== selectedEvent.id);
        setEvents(updatedEvents);
        setOpenDetailDialog(false);
        handleCloseConfirmDialog(); // ปิด Dialog หลังจากลบเสร็จ
      } else {
        alert('ไม่สามารถทำรายการได้โปรดติดต่อ สวส.');
        console.error('Error deleting event:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  //const filteredEvents = events.filter((event) => {
  //  const matchName = event.event_name.toLowerCase().includes(filter.search.toLowerCase());
  //  const matchType = !filter.type || event.event_type === filter.type;
  //  const matchCategory = !filter.category || event.event_categories === filter.category;
  //  const matchDepartment = !filter.department || event.event_department === filter.department;
  //  const matchStartDate = !filter.startDate || new Date(event.start) >= new Date(filter.startDate);
  //  const matchEndDate = !filter.endDate || new Date(event.end) <= new Date(filter.endDate);
  //  return matchName && matchType && matchCategory && matchDepartment && matchStartDate && matchEndDate;
  //});

  const filteredEvents = events.filter((event) => {
    const matchName = event.event_name.toLowerCase().includes(filter.search.toLowerCase());
    const matchDetail = event.event_detail ? event.event_detail.toLowerCase().includes(filter.search.toLowerCase()) : false; // ตรวจสอบว่ามี event_detail หรือไม่
    const matchType = !filter.type || event.event_type === filter.type;
    const matchCategory = !filter.category || event.event_categories === filter.category;
    const matchDepartment = !filter.department || event.event_department === filter.department;
    const matchStartDate = !filter.startDate || new Date(event.start) >= new Date(filter.startDate);
    const matchEndDate = !filter.endDate || new Date(event.end) <= new Date(filter.endDate);
    return (matchName || matchDetail) && matchType && matchCategory && matchDepartment && matchStartDate && matchEndDate;
  });

  const handleToggleFilter = () => {
    setFilterVisible(!filterVisible);
  };


  /*const Legend = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 3 }}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>ประเภทกิจกรรม</Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box sx={{ backgroundColor: '#2ec4b6', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">ประชุม</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#98c1d9', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">อบรม</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#a7c957', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">สัมมนา</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#ffbf69', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">กิจกรรม</Typography>
            </Grid>
  
            <Grid item>
              <Box sx={{ backgroundColor: '#ff99c8', width: '20px', height: '20px', borderRadius: '3px' }}></Box>
            </Grid>
            <Grid item>
              <Typography variant="body2">อื่นๆ</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };*/


  const eventPropGetter = (event) => {
    let backgroundColor = '';

    switch (event.event_type) {
      case 'ประชุม':
        backgroundColor = '#2ec4b6';
        break;
      case 'อบรม':
        backgroundColor = '#98c1d9';
        break;
      case 'สัมมนา':
        backgroundColor = '#a7c957';
        break;
      case 'กิจกรรม':
        backgroundColor = '#ffbf69';
        break;
      default:
        backgroundColor = '#ff99c8';
        break;
    }

    return {
      style: {
        backgroundColor, 
        color: 'black', // เปลี่ยนสีตัวหนังสือเป็นสีดำ
      },
    };
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
        <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }} 
            onClick={() => navigate('/admin-calendar')}
          >
            ระบบปฏิทินกลาง มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
          </Typography>
          {role === 'creator' && (
            <Button color="inherit" onClick={() => navigate('/admin-events')}>
              กิจกรรมของฉัน
            </Button>
          )}

          {role === 'admin' && (
            <>
              <Button color="inherit" onClick={() => navigate('/admin-events')}>
                กิจกรรมของฉัน
              </Button>
              <Button color="inherit" onClick={() => navigate('/admin-usersmgt')}>
                จัดการผู้ใช้
              </Button>
            </>
          )}
          <Button color="inherit" onClick={handleLogout}>ออกจากระบบ</Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: 4 }}>
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography variant="h6">ตัวกรองปฏิทิน</Typography>
            <IconButton onClick={handleToggleFilter}>
              {filterVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Collapse in={filterVisible} timeout="auto" unmountOnExit>
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>รูปแบบกิจกรรม</InputLabel>
                  <Select
                    name="type"
                    value={filter.type}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value="ประชุม">ประชุม</MenuItem>
                    <MenuItem value="อบรม">อบรม</MenuItem>
                    <MenuItem value="สัมมนา">สัมมนา</MenuItem>
                    <MenuItem value="กิจกรรม">กิจกรรม</MenuItem>
                    <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>ประเภท/หมวดหมู่</InputLabel>
                  <Select
                    name="category"
                    value={filter.category}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value="ทั่วไป">ทั่วไป</MenuItem>
                    <MenuItem value="วันสำคัญ">วันสำคัญ</MenuItem>
                    <MenuItem value="ปฏิทินการศึกษา">ปฏิทินการศึกษา</MenuItem>
                    <MenuItem value="วันหยุดราชการประจำปี">วันหยุดราชการประจำปี</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>หน่วยงานที่รับผิดชอบ</InputLabel>
                  <Select
                    name="department"
                    value={filter.department}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                    <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                    <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                    <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                    <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                    <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                    <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                    <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                    <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                    <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                    <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                    <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                    <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                    <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                    <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                    <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                    <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                    <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                    <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                    <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                    <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                    <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                    <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                    <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                    <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" style={{ marginBottom: '20px' }}>
                  <Button variant="outlined" color="secondary" onClick={handleResetFilter}>
                    รีเซ็ตตัวกรอง
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Collapse>

          <Box display="flex" justifyContent="flex-start" style={{ marginBottom: '20px' }}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              เพิ่มกิจกรรมใหม่
            </Button>
          </Box>
          
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, marginTop: '20px' }}
            selectable={true}
            onSelectSlot={handleSelectSlot}  // Make sure to call the defined function here
            onSelectEvent={handleEventClick}
            eventPropGetter={eventPropGetter}
          />

          <AddEventForm
            open={openForm}
            handleClose={handleCloseForm}
            handleAddEvent={isEditing ? handleSaveEditEvent : handleAddEvent}
            newEvent={newEvent}
            setNewEvent={setNewEvent}
          />

          {selectedEvent && (
            <Dialog open={openDetailDialog} onClose={handleCloseDetailDialog} fullWidth={true} maxWidth="sm">
              <DialogTitle>{selectedEvent.event_name}</DialogTitle>
              <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarTodayIcon sx={{ marginRight: 1, color: '#42a5f5' }} />
                    <Typography variant="body1" color="textPrimary">
                      วันที่: {new Date(selectedEvent.start).toLocaleDateString()} เวลา: {new Date(selectedEvent.start).toLocaleTimeString()} - {new Date(selectedEvent.end).toLocaleTimeString()}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EventIcon sx={{ marginRight: 1, color: '#42a5f5' }} />
                    <Typography variant="body1" color="textPrimary">
                      {selectedEvent.event_detail ? selectedEvent.event_detail : 'ไม่มีรายละเอียด'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PlaceIcon sx={{ marginRight: 1, color: '#ff7043' }} />
                    <Typography variant="body1" color="textPrimary">
                      สถานที่: {selectedEvent.event_place || 'ไม่ระบุสถานที่'}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CategoryIcon sx={{ marginRight: 1, color: '#66bb6a' }} />
                    <Typography variant="body1" color="textPrimary">
                      ประเภท: {selectedEvent.event_type}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LabelIcon sx={{ marginRight: 1, color: '#ab47bc' }} />
                    <Typography variant="body1" color="textPrimary">
                      หมวดหมู่: {selectedEvent.event_categories}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <BusinessIcon sx={{ marginRight: 1, color: '#ffa726' }} />
                    <Typography variant="body1" color="textPrimary">
                      หน่วยงาน: {selectedEvent.event_department}
                    </Typography>
                  </Box>
                  <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <DescriptionIcon sx={{ marginRight: 1, color: '#ff7043' }} />
                      <Typography variant="body1" color="textPrimary">
                        หมายเหตุ: {selectedEvent?.event_remark}
                      </Typography>
                    </Box>
                    <Divider />

                    {selectedEvent?.event_link && selectedEvent.event_link.trim() !== '' && selectedEvent.event_link !== '-' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                        <Typography variant="body1" color="textPrimary">
                          ลิงค์: <a 
                            href={selectedEvent.event_link.startsWith('http://') || selectedEvent.event_link.startsWith('https://') 
                                  ? selectedEvent.event_link 
                                  : `https://${selectedEvent.event_link}`} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            style={{ color: '#1976d2', textDecoration: 'none' }}
                          >
                            ข้อมูลเพิ่มเติม
                          </a>
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LinkIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                        <Typography variant="body1" color="textPrimary">
                          ลิงค์: -
                        </Typography>
                      </Box>
                    )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditEvent} color="primary" variant="outlined">แก้ไข</Button>
                <Button onClick={handleOpenConfirmDialog} color="secondary" variant="contained">ลบ</Button>
                <Button onClick={handleCloseDetailDialog} color="primary">ปิด</Button>
              </DialogActions>
              <Dialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
              >
                <DialogTitle>ยืนยันการลบ</DialogTitle>
                <DialogContent>
                  <Typography>คุณแน่ใจว่าต้องการลบกิจกรรมนี้หรือไม่?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirmDialog} color="primary">
                    ยกเลิก
                  </Button>
                  <Button onClick={handleDeleteEvent} color="secondary">
                    ลบ
                  </Button>
                </DialogActions>
              </Dialog>
            </Dialog>
          )}
        </Paper>
      </Container>

      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="body1">© 2024 ระบบปฏิทินกลาง - มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร</Typography>
      </Box>
    </div>
  );
};

export default AdminCalendar;