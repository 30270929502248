import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Select, MenuItem, FormControl, InputLabel, Collapse, Typography, IconButton, Box, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const AddEventForm = ({ open, handleClose, handleAddEvent, newEvent, setNewEvent }) => {
  const [errorMessage, setErrorMessage] = useState(''); // State สำหรับเก็บข้อความข้อผิดพลาด

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  // Function to format datetime to `YYYY-MM-DDTHH:MM`
  //const formatDateTime = (date) => {
  //  if (!date) return ''; // ถ้า date ว่างให้ส่งกลับเป็นค่าว่าง
  //  const d = new Date(date);
  //  if (isNaN(d.getTime())) return ''; // ถ้าไม่ใช่วันที่ที่ถูกต้องให้ส่งกลับค่าว่าง
  //  return d.toISOString().slice(0, 16); // แปลงเป็นรูปแบบที่ `datetime-local` ต้องการ
  //};

  const formatDateTime = (date) => {
    if (!date) return ''; // ถ้า date ว่างให้ส่งกลับเป็นค่าว่าง
    const d = new Date(date);
    if (isNaN(d.getTime())) return ''; // ถ้าไม่ใช่วันที่ที่ถูกต้องให้ส่งกลับค่าว่าง
    
    // แปลงวันที่และเวลาให้เป็นเวลาท้องถิ่นโดยใช้ moment.js หรือ JavaScript ธรรมดา
    const offsetDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000); // ลบ timezone offset ออก
    return offsetDate.toISOString().slice(0, 16); // ใช้เฉพาะส่วนของวันที่และเวลา
  };

  // แปลงวันที่ให้เป็น UTC
//const toUTC = (date) => {
//  if (!date) return ''; // ถ้า date ว่างให้ส่งกลับเป็นค่าว่าง
//  const d = new Date(date);
//  return new Date(d.getTime() + d.getTimezoneOffset() * 60000).toISOString();
//};

const handleSubmit = () => {
  if (!newEvent.event_name) {
    setErrorMessage('กรุณากรอกชื่อกิจกรรม');
    return;
  }
  
  // ส่งข้อมูลกลับไปที่ AdminCalendar
  handleAddEvent(newEvent);
  handleClose();
};

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const toggleAdditionalInfo = () => {
    setShowAdditionalInfo(!showAdditionalInfo);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>เพิ่มกิจกรรมใหม่</DialogTitle>
      <DialogContent>
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
          <Grid container spacing={2}>
            {/* ส่วนที่ 1: รายละเอียดกิจกรรม */}
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#1976d2' }}>รายละเอียดกิจกรรม</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อกิจกรรม"
                name="event_name"
                value={newEvent.event_name || ''} // กำหนดค่าเริ่มต้นเป็นข้อความว่าง
                onChange={handleChange}
                required
                error={!newEvent.event_name && errorMessage !== ''} // แสดงข้อผิดพลาดถ้าชื่อกิจกรรมว่าง
                helperText={!newEvent.event_name ? errorMessage : ''}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>รูปแบบ</InputLabel>
                <Select
                  name="event_type"
                  value={newEvent.event_type || ''} // กำหนดค่าเริ่มต้น
                  onChange={handleChange}
                >
                  <MenuItem value="ประชุม">ประชุม</MenuItem>
                  <MenuItem value="อบรม">อบรม</MenuItem>
                  <MenuItem value="สัมมนา">สัมมนา</MenuItem>
                  <MenuItem value="กิจกรรม">กิจกรรม</MenuItem>
                  <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>ประเภท/หมวดหมู่</InputLabel>
                <Select
                  name="event_categories"
                  value={newEvent.event_categories || ''} // กำหนดค่าเริ่มต้น
                  onChange={handleChange}
                >
                  <MenuItem value="ทั่วไป">ทั่วไป</MenuItem>
                  <MenuItem value="วันสำคัญ">วันสำคัญ</MenuItem>
                  <MenuItem value="ปฏิทินการศึกษา">ปฏิทินการศึกษา</MenuItem>
                  <MenuItem value="วันหยุดราชการประจำปี">วันหยุดราชการประจำปี</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>หน่วยงานที่รับผิดชอบ</InputLabel>
                <Select
                  name="event_department"
                  value={newEvent.event_department || ''} // กำหนดค่าเริ่มต้น
                  onChange={handleChange}
                >
                  <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                  <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                  <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                  <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                  <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                  <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                  <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                  <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                  <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                  <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                  <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                  <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                  <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                  <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                  <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                  <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                  <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                  <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                  <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                  <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                  <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                  <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                  <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                  <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                  <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
            <TextField
              fullWidth
              label="รายละเอียด"
              name="event_detail"
              value={newEvent.event_detail || ''} // กำหนดค่าเริ่มต้นเป็นข้อความว่าง
              onChange={handleChange}
              multiline // เปลี่ยนให้เป็น textarea
              rows={2} // กำหนดจำนวนแถวเริ่มต้น
            />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="สถานที่จัด"
                name="event_place"
                value={newEvent.event_place || ''} // กำหนดค่าเริ่มต้นเป็นข้อความว่าง
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="วันที่และเวลาเริ่มต้น"
                name="event_start_datetime"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                value={formatDateTime(newEvent.event_start_datetime)} // ใช้ฟังก์ชันแปลงเวลา
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="วันที่และเวลาสิ้นสุด"
                name="event_end_datetime"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                value={formatDateTime(newEvent.event_end_datetime)} // ใช้ฟังก์ชันแปลงเวลา
                onChange={handleChange}
              />
            </Grid>

            {/* ส่วนที่ 2: ข้อมูลเพิ่มเติม */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" style={{ color: '#1976d2' }}>ข้อมูลเพิ่มเติม</Typography>
                <IconButton onClick={toggleAdditionalInfo}>
                  {showAdditionalInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            </Grid>

            <Collapse in={showAdditionalInfo} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="เพิ่มลิงค์เว็บไซต์"
                    name="event_link"
                    value={newEvent.event_link || ''} // กำหนดค่าเริ่มต้น
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="หมายเหตุ"
                    name="event_remark"
                    value={newEvent.event_remark || ''} // กำหนดค่าเริ่มต้นเป็นข้อความว่าง
                    onChange={handleChange}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">ยกเลิก</Button>
        <Button onClick={handleSubmit} color="primary">บันทึก</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventForm;