import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Snackbar, Alert, TextField, Container, Box, Typography, Paper, FormControl, InputLabel, Select, MenuItem, Grid, Button, AppBar, Toolbar, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import moment from 'moment';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { jwtDecode } from 'jwt-decode'; // เปลี่ยนการ import ให้ถูกต้อง
import { useNavigate } from 'react-router-dom';

// สไตล์เพิ่มเติมสำหรับการจัดเรียง row ตาม role
const conditionalRowStyles = [
  {
    when: row => row.role === 'admin',
    style: {
      backgroundColor: '#ffecb3',
      color: '#000000',
    },
  },
  {
    when: row => row.role === 'creator',
    style: {
      backgroundColor: '#e3f2fd',
      color: '#000000',
    },
  },
];

// สไตล์หัวตาราง
const customStyles = {
  headCells: {
    style: {
      backgroundColor: '#1976d2',
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '14px',
      paddingLeft: '16px',
      paddingRight: '16px',
      textAlign: 'center',
    },
  },
};

const UserManagement = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]); // เก็บข้อมูลผู้ใช้ที่ดึงมาจาก backend
  const [filterText, setFilterText] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // state สำหรับ confirm dialog
  const [selectedUser, setSelectedUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null); // เก็บผู้ใช้ที่ต้องการลบ
  const [isEditing, setIsEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // ตรวจสอบว่าเป็น admin หรือไม่
  const [errorMessage, setErrorMessage] = useState(''); // เก็บข้อความ error
  const [snackbarOpen, setSnackbarOpen] = useState(false); // ควบคุมการแสดง Snackbar

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/admin-login'); // หากไม่มี token ให้ส่งไปที่หน้า login
    } else {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // เวลาในหน่วยวินาที

        if (decodedToken.exp < currentTime) {
          // Token หมดอายุแล้ว
          localStorage.clear();
          navigate('/admin-login'); // ส่งไปที่หน้า login
        } else {
          // ถ้า token ยังไม่หมดอายุ ตรวจสอบว่า role เป็น admin หรือไม่
          if (decodedToken.role === 'admin') {
            setIsAdmin(true);
            fetchUsers(token); // ดึงข้อมูลผู้ใช้จาก backend
          } else {
            navigate('/admin-calendar'); // ถ้าไม่ใช่ admin ส่งไปที่หน้า unauthorized
          }
        }
      } catch (error) {
        console.error('Token error:', error);
        localStorage.clear();
        navigate('/admin-login');
      }
    }
  }, [navigate]);

  const fetchUsers = (token) => {
    fetch('https://e-calendar.rmutp.ac.th/api/user_list', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        const formattedUsers = data.map(user => ({
          id: user.id,
          username: user.username,
          name: user.name,
          surname: user.surname,
          department: user.department,
          role: user.role,
          created_date: user.created_date,
        }));
        setUsers(formattedUsers); // เก็บข้อมูลใน state
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
    setDialogOpen(true);
  };

  // ฟังก์ชันเปิด confirm dialog
  const handleDeleteUser = (user) => {
    console.log('User to delete:', user); // Ensure the user object has the necessary data
    if (user && user.id) {
      setUserToDelete(user); // Set the user to delete
      setConfirmDialogOpen(true); // Open confirm dialog
    } else {
      console.error('User object does not have an ID:', user);
    }
  };
  
  const handleConfirmDelete = () => {
    const token = localStorage.getItem('token');
    
    if (userToDelete && userToDelete.id) { // Check if userToDelete and userToDelete.id are set
      console.log('Deleting user with ID:', userToDelete.id); // Debugging statement
  
      fetch(`https://e-calendar.rmutp.ac.th/api/delete_user/${userToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            setUsers(users.filter(user => user.id !== userToDelete.id));
            setConfirmDialogOpen(false);
            setUserToDelete(null);
            window.location.reload(); // Reload หลังจากลบสำเร็จ
          } else {
            return response.json().then(err => {
              throw new Error(err.message || 'Failed to delete user');
            });
          }
        })
        .catch(error => {
          alert(`Error deleting user: ${error.message}`);
          console.error('Delete error:', error);
        });
    } else {
      console.error('User to delete or user ID is undefined:', userToDelete); // More detailed error logging
    }
  };

  const handleSaveUser = () => {

    // ตรวจสอบว่าฟิลด์ทุกฟิลด์มีค่าหรือไม่
    if (!selectedUser.name || !selectedUser.surname || !selectedUser.department || !selectedUser.username || !selectedUser.role) {
      setErrorMessage('กรุณากรอกข้อมูลให้ครบถ้วน');
      setSnackbarOpen(true); // แสดง Snackbar เมื่อกรอกข้อมูลไม่ครบ
      return; // หยุดการทำงานถ้าฟิลด์ใดยังไม่ได้กรอก
    }
    const token = localStorage.getItem('token');
  
    if (isEditing) {
      // ถ้าเป็นการแก้ไขผู้ใช้
      fetch(`https://e-calendar.rmutp.ac.th/api/update_user/${selectedUser.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: selectedUser.name,
          surname: selectedUser.surname,
          department: selectedUser.department,
          username: selectedUser.username,
          role: selectedUser.role,
        }),
      })
        .then(response => {
          if (!response.ok) {
            // ถ้า response ไม่ success ให้แสดงข้อความ error
            return response.json().then(err => {
              throw new Error(err.message || 'Failed to update user');
            });
          }
          return response.json();
        })
        .then(updatedUser => {
          setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
          setDialogOpen(false);
          window.location.reload(); // Reload หลังจากแก้ไขสำเร็จ
        })
        .catch(error => {
          //alert(`Error updating user: ${error.message}`);
          setErrorMessage(error.message); // ตั้งค่า error message
          setSnackbarOpen(true); // แสดง Snackbar
        });
    } else {
      // ถ้าเป็นการเพิ่มผู้ใช้ใหม่
      fetch('https://e-calendar.rmutp.ac.th/api/add_user', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: selectedUser.name,
          surname: selectedUser.surname,
          department: selectedUser.department,
          username: selectedUser.username,
          role: selectedUser.role,
          created_date: moment().format('YYYY-MM-DD'), // กำหนดวันที่สร้าง
        }),
      })
        .then(response => {
          if (!response.ok) {
            // ถ้า response ไม่ success ให้แสดงข้อความ error
            return response.json().then(err => {
              throw new Error(err.message || 'Failed to add user');
            });
          }
          return response.json();
        })
        .then(addedUser => {
          setUsers([...users, addedUser]);
          setDialogOpen(false);
          window.location.reload(); // Reload หลังจากเพิ่มสำเร็จ
        })
        .catch(error => {
          //alert(`Error adding user: ${error.message}`);
          setErrorMessage(error.message); // ตั้งค่า error message
          setSnackbarOpen(true); // แสดง Snackbar
        });
    }
  };
  // ฟังก์ชันปิด Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const handleAddUser = () => {
    setSelectedUser({
      name: '',
      surname: '',
      department: '',
      username: '',
      role: '',
      created_date: moment().format('YYYY-MM-DD'), // กำหนดวันที่สร้าง
    });
    setIsEditing(false);
    setDialogOpen(true);
  };

  const handleClearFilters = () => {
    setFilterText('');
    setSelectedRole('');
    setSelectedDepartment('');
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false); // ปิด dialog ยืนยันการลบ
    setUserToDelete(null); // รีเซ็ตข้อมูล
  };

  const filteredData = users.filter(user =>
    user.username?.toLowerCase().includes(filterText.toLowerCase()) && // ใช้ optional chaining เพื่อตรวจสอบว่ามีค่า username หรือไม่
    (selectedDepartment === '' || user.department === selectedDepartment) &&
    (selectedRole === '' || user.role === selectedRole)
  );

  const columns = [
    {
      name: 'Username',
      selector: row => row.username,
      sortable: true,
      cell: row => <span style={{ fontWeight: 'bold' }}>{row.username}</span>, // ทำให้ตัวหนา
    },
    {
      name: 'ชื่อ',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'นามสกุล',
      selector: row => row.surname,
      sortable: true,
    },
    {
      name: 'หน่วยงาน',
      selector: row => row.department,
      sortable: true,
    },
    {
      name: 'Role',
      selector: row => row.role,
      sortable: true,
      cell: row => <span style={{ fontWeight: 'bold' }}>{row.role}</span>, // ทำให้ตัวหนา
    },
    {
      name: 'วันที่สร้าง',
      selector: row => moment(row.created_date).format('YYYY-MM-DD'),
      sortable: true,
    },
    {
      name: 'การจัดการ',
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
          <IconButton aria-label="edit" color="primary" size="small" onClick={() => handleEditUser(row)} style={{ marginRight: '5px' }}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" color="secondary" size="small" onClick={() => handleDeleteUser(row)}> {/* Pass the full row */}
            <DeleteIcon />
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    isAdmin ? (
      <div>
        {/* Snackbar แสดงข้อความ error */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
        <AppBar position="static">
        <Toolbar>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }} 
            onClick={() => navigate('/admin-calendar')}
          >
            ระบบปฏิทินกลาง มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
          </Typography>
          <Button color="inherit" onClick={() => navigate('/admin-calendar')}>กลับไปหน้าปฏิทิน</Button>
        </Toolbar>
        </AppBar>

        <Container sx={{ marginTop: 4 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="ค้นหา Username"
                  variant="outlined"
                  fullWidth
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>หน่วยงาน</InputLabel>
                  <Select
                    value={selectedDepartment}
                    onChange={e => setSelectedDepartment(e.target.value)}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                    <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                    <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                    <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                    <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                    <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                    <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                    <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                    <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                    <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                    <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                    <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                    <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                    <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                    <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                    <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                    <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                    <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                    <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                    <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                    <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                    <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                    <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                    <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                    <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={selectedRole}
                    onChange={e => setSelectedRole(e.target.value)}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value="creator">creator</MenuItem>
                    <MenuItem value="admin">admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleAddUser} // ฟังก์ชันที่ใช้เปิด Dialog เพื่อเพิ่มผู้ใช้ใหม่
                  sx={{ marginRight: 1 }}  // เพิ่มระยะห่างทางขวา
                >
                  เพิ่มผู้ใช้ใหม่
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleClearFilters}>
                  รีเซ็ตตัวกรอง
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Paper elevation={3}>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              striped
              noHeader
              conditionalRowStyles={conditionalRowStyles}
              customStyles={customStyles}
            />
          </Paper>
        </Container>

        {/* Dialog สำหรับเพิ่มและแก้ไขผู้ใช้ */}
        {selectedUser && (
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>{isEditing ? 'แก้ไขข้อมูลผู้ใช้' : 'เพิ่มผู้ใช้ใหม่'}</DialogTitle>
            <DialogContent dividers>
            <TextField
              label="ชื่อ"
              fullWidth
              margin="dense"
              value={selectedUser.name}
              onChange={e => setSelectedUser({ ...selectedUser, name: e.target.value })}
            />
            <TextField
              label="นามสกุล"
              fullWidth
              margin="dense"
              value={selectedUser.surname}
              onChange={e => setSelectedUser({ ...selectedUser, surname: e.target.value })}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>หน่วยงาน</InputLabel>
              <Select
                value={selectedUser.department || ''}
                onChange={e => setSelectedUser({ ...selectedUser, department: e.target.value })}
              >
                <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
              </Select>
            </FormControl>
              <TextField
                label="Username"
                fullWidth
                margin="dense"
                value={selectedUser.username}
                onChange={e => setSelectedUser({ ...selectedUser, username: e.target.value })}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Role</InputLabel>
                <Select
                  value={selectedUser.role}
                  onChange={e => setSelectedUser({ ...selectedUser, role: e.target.value })}
                >
                  <MenuItem value="creator">creator</MenuItem>
                  <MenuItem value="admin">admin</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                ยกเลิก
              </Button>
              <Button onClick={handleSaveUser} color="primary">
                {isEditing ? 'บันทึก' : 'บันทึก'}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Confirm Dialog สำหรับการลบผู้ใช้ */}
        <Dialog
            open={confirmDialogOpen}
            onClose={handleCancelDelete}
          >
            <DialogTitle>ยืนยันการลบ</DialogTitle>
            <DialogContent>
              {/* Ensure name and surname are displayed correctly */}
              <Typography>คุณแน่ใจว่าต้องการลบผู้ใช้ {userToDelete?.name} {userToDelete?.surname} หรือไม่?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete} color="primary">
                ยกเลิก
              </Button>
              <Button onClick={handleConfirmDelete} color="secondary">
                ลบ
              </Button>
            </DialogActions>
          </Dialog>
        {/* Footer */}
        <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="body1">© 2024 ระบบปฏิทินกลาง - มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร</Typography>
      </Box>
      </div>
    ) : (
      <Typography variant="h6" align="center" sx={{ mt: 5 }}>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</Typography>
    )
  );
};

export default UserManagement;