import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CalendarView from './pages/CalendarView';  // สร้างหน้า CalendarView ในสเตปต่อไป
import AdminLogin from './pages/AdminLogin';
import AdminCalendar from './pages/AdminCalendar';
import EventTable from './pages/EventTable';
import EventAdminTable from './pages/EventAdminTable';
import UserManagement from './pages/UserManagement';


function App() {
  useEffect(() => {
    document.title = 'ระบบปฏิทินกลาง มทร. พระนคร'; // เปลี่ยน title ของหน้าเว็บ
  }, []);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CalendarView />} />
        <Route path="/events" element={<EventTable />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-calendar" element={<AdminCalendar />} />
        <Route path="/admin-events" element={<EventAdminTable/>} />
        <Route path="/admin-usersmgt" element={<UserManagement/>} />
      </Routes>
    </Router>
  );
}

export default App;