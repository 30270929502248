import React, { useState } from 'react';
import { Button, TextField, Container, Paper, Typography, Box, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // ใช้สำหรับการนำทาง

  const handleLogin = async () => {
    try {
      const response = await fetch('https://e-calendar.rmutp.ac.th/api/admin_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok && data.message === 'Success') {
        // เก็บ token ใน localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', data.username);
        localStorage.setItem('name', data.name);
        localStorage.setItem('surname', data.surname);
        localStorage.setItem('department', data.department);
        localStorage.setItem('role', data.role);

        // นำผู้ใช้ไปยังหน้า admin-calendar
        navigate('/admin-calendar');
      } else {
        setErrorMessage('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง');
      }
    } catch (error) {
      setErrorMessage('เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์');
    }
  };

  // ฟังก์ชันจัดการเมื่อกด Enter
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div>
      {/* Header */}
      <AppBar position="static">
      <Toolbar>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }} 
            onClick={() => navigate('/')}
          >
            ระบบปฏิทินกลาง มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
          </Typography>
          <Button color="inherit" onClick={() => navigate('/')}>กลับไปหน้าปฏิทิน</Button>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h5" gutterBottom>สำหรับผู้บันทึกข้อมูล</Typography>
          <Typography variant="body1" gutterBottom>
            กรุณาใช้ RMUTP Passport ของคุณในการเข้าสู่ระบบ
          </Typography>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              onKeyPress={handleKeyPress} // ใช้ฟังก์ชันตรวจจับการกด Enter
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              onKeyPress={handleKeyPress} // ใช้ฟังก์ชันตรวจจับการกด Enter
            />
            {errorMessage && (
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
            )}
            <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
          </Box>
        </Paper>
      </Container>

      {/* Footer */}
      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="body1">© 2024 ระบบปฏิทินกลาง - มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร</Typography>
      </Box>
    </div>
  );
};

export default AdminLogin;