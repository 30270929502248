import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { 
  TextField, 
  Container, 
  Box, 
  Typography, 
  Paper, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Grid, 
  Button, 
  AppBar, 
  Toolbar, 
  Checkbox,
  //Dialog, 
  //DialogTitle, 
  //DialogContent, 
  //DialogActions, 
  IconButton,
  //Divider,
  FormControlLabel 
} from '@mui/material';
//import CloseIcon from '@mui/icons-material/Close';
//import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
//import EventIcon from '@mui/icons-material/Event';
//import PlaceIcon from '@mui/icons-material/Place';
//import CategoryIcon from '@mui/icons-material/Category';
//import LabelIcon from '@mui/icons-material/Label';
//import BusinessIcon from '@mui/icons-material/Business';
//import DescriptionIcon from '@mui/icons-material/Description';
//import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // เปลี่ยนการ import ให้ถูกต้อง
import AddEventForm from '../components/AddEventForm'; // Assume AddEventForm is in the same directory

const EventAdminTable = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [showAllEvents, setShowAllEvents] = useState(false); 
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  /*useEffect(() => {
    // ตรวจสอบ token
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/admin-login'); // redirect ไปยังหน้าล็อกอินถ้าไม่มี token
    } else {
      try {
        const decodedToken = jwtDecode(token);
        // ตรวจสอบว่าหมดอายุหรือไม่
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('token'); // ลบ token ที่หมดอายุ
          navigate('/admin-login'); // redirect ไปยังหน้าล็อกอิน
        }
      } catch (error) {
        console.error('Token error:', error);
        navigate('/admin-login'); // redirect ไปยังหน้าล็อกอินหากมีข้อผิดพลาด
      }
    }

    fetch('https://e-calendar.rmutp.ac.th/api/event_listmgt', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`  // ส่ง token ไปใน Authorization header
          //'Content-Type': 'application/json'   // กำหนด Content-Type เป็น JSON
        },
    })
      .then(response => response.json())
      .then(data => {
        const formattedEvents = data.map(event => ({
          id: event.event_id,
          title: event.event_name,
          event_name: event.event_name,
          event_type: event.event_type,
          event_categories: event.event_categories,
          event_department: event.event_department,
          event_place: event.event_place,
          event_detail: event.event_detail,
          event_remark: event.event_remark,
          event_link: event.event_link,
          start: event.event_start_datetime,
          end: event.event_end_datetime
        }));
  
        // Sort by start date
        const sortedEvents = formattedEvents.sort((a, b) => moment(a.start).toDate() - moment(b.start).toDate());
        setEvents(sortedEvents);
      })
      .catch(error => {
        console.error('Error fetching events:', error);
      });
  }, []);*/

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      navigate('/admin-login');
    } else {
      try {
        // Decode token เพื่อตรวจสอบ expiration time
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // เวลาในหน่วยวินาที
  
        if (decodedToken.exp < currentTime) {
          // Token หมดอายุแล้ว
          localStorage.clear(); // ล้าง localStorage
          navigate('/admin-login'); // ส่งผู้ใช้กลับไปหน้า login
        } else {
          // ถ้า token ยังไม่หมดอายุ ให้ fetch ข้อมูล
          fetch('https://e-calendar.rmutp.ac.th/api/event_listmgt', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => response.json())
            .then(data => {
              const formattedEvents = data.map(event => ({
                id: event.event_id,
                title: event.event_name,
                start: new Date(event.event_start_datetime),
                end: new Date(event.event_end_datetime),
                event_name: event.event_name,
                event_type: event.event_type,
                event_categories: event.event_categories,
                event_department: event.event_department,
                event_place: event.event_place,
                event_detail: event.event_detail,
                event_remark: event.event_remark,
                event_link: event.event_link
              }));
               // จัดเรียงข้อมูลตามวันที่เริ่มต้น (start)
        const sortedEvents = formattedEvents.sort((a, b) => moment(a.start).toDate() - moment(b.start).toDate());
            setEvents(sortedEvents); // เก็บข้อมูลที่จัดเรียงแล้วลงใน state
            })
              //setEvents(formattedEvents);
            //})
            .catch(error => {
              console.error('Error fetching events:', error);
            });
        }
      } catch (error) {
        console.error('Invalid token', error);
        localStorage.clear(); // ล้าง localStorage
        navigate('/admin-login'); // ส่งผู้ใช้กลับไปหน้า login
      }
    }
  }, [navigate]);




  const filteredEvents = events.filter(event => {
    if (!showAllEvents) {
      const startOfCurrentMonth = moment().startOf('month');
      return moment(event.start).isSameOrAfter(startOfCurrentMonth);
    }
    return true;
  }).filter(event =>
    event.event_name.toLowerCase().includes(filterText.toLowerCase()) &&
    (selectedType === '' || event.event_type === selectedType) &&
    (selectedCategory === '' || event.event_categories === selectedCategory) &&
    (selectedDepartment === '' || event.event_department === selectedDepartment)
  );

  const columns = [
    { 
      name: 'วันที่', 
      selector: row => moment(row.start).format('DD/MM/YYYY'), 
      sortable: true,
      sortFunction: (a, b) => moment(a.start).toDate() - moment(b.start).toDate(),
    },
    { 
      name: 'เวลา', 
      selector: row => `${moment(row.start).local().format('HH:mm')} - ${moment(row.end).local().format('HH:mm')}`, 
      sortable: false 
    },
    { name: 'ชื่อกิจกรรม', selector: row => row.event_name, sortable: true },
    {
      name: 'รูปแบบกิจกรรม',
      selector: row => row.event_type,
      sortable: true,
      cell: row => (
        <span style={{
          color: 'white',
          backgroundColor: getEventTypeColor(row.event_type),
          padding: '5px',
          borderRadius: '5px',
          textAlign: 'center',
          display: 'inline-block',
          width: '100%',
        }}>
          {row.event_type}
        </span>
      ),
    },
    { name: 'ประเภท/หมวดหมู่', selector: row => row.event_categories, sortable: true },
    { name: 'หน่วยงานที่รับผิดชอบ', selector: row => row.event_department, sortable: true },
    {
      name: 'การจัดการ',
      cell: row => (
        <div>
          <IconButton onClick={() => handleEdit(row)}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon color="secondary" />
          </IconButton>
        </div>
      ),
    }
  ];

  const getEventTypeColor = (eventType) => {
    switch (eventType) {
      case 'ประชุม':
        return '#2ec4b6'; 
      case 'อบรม':
        return '#98c1d9'; 
      case 'สัมมนา':
        return '#a7c957'; 
      case 'กิจกรรม':
        return '#ffbf69'; 
      default:
        return '#ff99c8'; 
    }
  };

  const conditionalRowStyles = [
    {
      when: row => moment(row.end).isBefore(moment()),
      style: {
        backgroundColor: '#f0f0f0',
        color: '#999999',
      },
    },
  ];
  
// ประกาศฟังก์ชัน formatDateTime
const formatDateTime = (date) => {
    if (!date) return ''; 
    const d = new Date(date);
    const offsetDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000); 
    return offsetDate.toISOString().slice(0, 16); 
  };
  
  const handleEdit = (event) => {
    // แปลงเวลาที่เลือกจะแก้ไขให้เป็นรูปแบบที่ใช้งานได้กับ datetime-local
    const eventToEdit = {
      ...event,
      event_start_datetime: formatDateTime(event.start),
      event_end_datetime: formatDateTime(event.end)
    };
    
    setSelectedEvent(eventToEdit);
    setIsEditing(true);
    setDialogOpen(true);
  };

  const handleDelete = (event) => {
    if (window.confirm(`คุณต้องการลบกิจกรรม ${event.event_name} หรือไม่?`)) {
      fetch(`https://e-calendar.rmutp.ac.th/api/delete_event/${event.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // ส่ง token ใน Authorization header
        },
      })
      .then(response => {
        if (response.ok) {
          setEvents(events.filter(e => e.id !== event.id)); // ลบกิจกรรมออกจาก state
        } else {
          alert('คุณไม่ได้รับสิทธิ์ในการลบกิจกรรม');
        }
      })
      .catch(error => {
        console.error('Error deleting event:', error);
      });
    }
  };

  const handleAddEvent = (newEvent) => {
    if (isEditing) {
      fetch(`https://e-calendar.rmutp.ac.th/api/update_event/${newEvent.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,  // ใส่ token ที่ถูกต้องใน header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEvent),
      })
      .then(response => {
        if (!response.ok) {
          // ตรวจสอบสถานะการตอบกลับ ถ้าไม่ใช่ 2xx ให้แสดงข้อความ error
          return response.json().then(err => {
            throw new Error(err.message || 'ไม่สามารถทำรายการได้โปรดติดต่อ สวส.');
          });
        }
        return response.json(); // ดำเนินการต่อถ้า response success
      })
      .then(updatedEvent => {
        setEvents(events.map(e => (e.id === updatedEvent.id ? updatedEvent : e)));
        setIsEditing(false);
        setDialogOpen(false); // ปิด dialog หลังจากการอัปเดตสำเร็จ
        window.location.reload(); // รีโหลดหน้า
      })
      .catch(error => {
        alert(`เกิดข้อผิดพลาด: ${error.message}`);
        console.error('Error updating event:', error);
      });
    } else {
      fetch('https://e-calendar.rmutp.ac.th/api/add_event', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEvent),
      })
      .then(response => {
        if (!response.ok) {
          // ตรวจสอบสถานะการตอบกลับ ถ้าไม่ใช่ 2xx ให้แสดงข้อความ error
          return response.json().then(err => {
            throw new Error(err.message || 'ไม่สามารถเพิ่มข้อมูลได้');
          });
        }
        return response.json();
      })
      .then(addedEvent => {
        setEvents([...events, addedEvent]);
      })
      .catch(error => {
        alert(`เกิดข้อผิดพลาด: ${error.message}`);
        console.error('Error adding event:', error);
      });
    }
    setDialogOpen(false);
  };

  const handleClearFilters = () => {
    setFilterText('');
    setSelectedType('');
    setSelectedCategory('');
    setSelectedDepartment('');
    setShowAllEvents(false);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }} 
            onClick={() => navigate('/admin-calendar')}
          >
            ระบบปฏิทินกลาง มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร
          </Typography>
          <Button color="inherit" onClick={() => navigate('/admin-calendar')}>กลับไปหน้าปฏิทิน</Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ marginTop: 4 }}>
        <Box sx={{ marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                label="ค้นหากิจกรรม"
                variant="outlined"
                fullWidth
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>รูปแบบกิจกรรม</InputLabel>
                <Select
                  value={selectedType}
                  onChange={e => setSelectedType(e.target.value)}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  <MenuItem value="ประชุม">ประชุม</MenuItem>
                  <MenuItem value="อบรม">อบรม</MenuItem>
                  <MenuItem value="สัมมนา">สัมมนา</MenuItem>
                  <MenuItem value="กิจกรรม">กิจกรรม</MenuItem>
                  <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>ประเภท/หมวดหมู่</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={e => setSelectedCategory(e.target.value)}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  <MenuItem value="ทั่วไป">ทั่วไป</MenuItem>
                  <MenuItem value="วันสำคัญ">วันสำคัญ</MenuItem>
                  <MenuItem value="ปฏิทินการศึกษา">ปฏิทินการศึกษา</MenuItem>
                  <MenuItem value="วันหยุดราชการประจำปี">วันหยุดราชการประจำปี</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel>หน่วยงานที่รับผิดชอบ</InputLabel>
                <Select
                  value={selectedDepartment}
                  onChange={e => setSelectedDepartment(e.target.value)}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  <MenuItem value="คณะครุศาสตร์อุตสาหกรรม">คณะครุศาสตร์อุตสาหกรรม</MenuItem>
                  <MenuItem value="คณะเทคโนโลยีคหกรรมศาสตร์">คณะเทคโนโลยีคหกรรมศาสตร์</MenuItem>
                  <MenuItem value="คณะเทคโนโลยีสื่อสารมวลชน">คณะเทคโนโลยีสื่อสารมวลชน</MenuItem>
                  <MenuItem value="คณะบริหารธุรกิจ">คณะบริหารธุรกิจ</MenuItem>
                  <MenuItem value="คณะวิทยาศาสตร์และเทคโนโลยี">คณะวิทยาศาสตร์และเทคโนโลยี</MenuItem>
                  <MenuItem value="คณะวิศวกรรมศาสตร์">คณะวิศวกรรมศาสตร์</MenuItem>
                  <MenuItem value="คณะศิลปศาสตร์">คณะศิลปศาสตร์</MenuItem>
                  <MenuItem value="คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น">คณะอุตสาหกรรมสิ่งทอและออกแบบแฟชั่น</MenuItem>
                  <MenuItem value="คณะสถาปัตยกรรมศาสตร์และการออกแบบ">คณะสถาปัตยกรรมศาสตร์และการออกแบบ</MenuItem>
                  <MenuItem value="วิทยาลัยการบริหารแห่งรัฐ">วิทยาลัยการบริหารแห่งรัฐ</MenuItem>
                  <MenuItem value="สำนักงานอธิการบดี">สำนักงานอธิการบดี</MenuItem>
                  <MenuItem value="สำนักวิทยบริการและเทคโนโลยีสารสนเทศ">สำนักวิทยบริการและเทคโนโลยีสารสนเทศ</MenuItem>
                  <MenuItem value="สำนักส่งเสริมวิชาการและงานทะเบียน">สำนักส่งเสริมวิชาการและงานทะเบียน</MenuItem>
                  <MenuItem value="สถาบันวิจัยและพัฒนา">สถาบันวิจัยและพัฒนา</MenuItem>
                  <MenuItem value="สถาบันภาษา">สถาบันภาษา</MenuItem>
                  <MenuItem value="สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ">สถาบันอัญมณี เครื่องประดับไทย และการออกแบบ</MenuItem>
                  <MenuItem value="สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์">สถาบันสหวิทยาการดิจิทัลและหุ่นยนต์</MenuItem>
                  <MenuItem value="สภาคณาจารย์และข้าราชการ">สภาคณาจารย์และข้าราชการ</MenuItem>
                  <MenuItem value="สำนักงานตรวจสอบภายใน">สำนักงานตรวจสอบภายใน</MenuItem>
                  <MenuItem value="กองกลาง">กองกลาง</MenuItem>
                  <MenuItem value="กองบริหารงานบุคคล">กองบริหารงานบุคคล</MenuItem>
                  <MenuItem value="กองคลัง">กองคลัง</MenuItem>
                  <MenuItem value="กองนโยบายและแผน">กองนโยบายและแผน</MenuItem>
                  <MenuItem value="กองพัฒนานักศึกษา">กองพัฒนานักศึกษา</MenuItem>
                  <MenuItem value="มทร.พระนคร">มทร.พระนคร</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAllEvents}
                    onChange={e => setShowAllEvents(e.target.checked)}
                    color="primary"
                  />
                }
                label="แสดงข้อมูลทั้งหมด"
              />
              <Button variant="outlined" color="secondary" onClick={handleClearFilters}>
                รีเซ็ตตัวกรอง
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Paper elevation={3}>
          <DataTable
            columns={columns}
            data={filteredEvents}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            striped
            noHeader
            onRowClicked={handleEdit}
            conditionalRowStyles={conditionalRowStyles} 
          />
        </Paper>
      </Container>

      {selectedEvent && (
        <AddEventForm
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          handleAddEvent={handleAddEvent}
          newEvent={selectedEvent}
          setNewEvent={setSelectedEvent}
        />
      )}

      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
        <Typography variant="body1">© 2024 ระบบปฏิทินกลาง - มหาวิทยาลัยเทคโนโลยีราชมงคลพระนคร</Typography>
      </Box>
    </div>
  );
};

export default EventAdminTable;